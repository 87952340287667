export const useDateOfBirthInputTextConfig = () => {
  const { country } = useRouteHelper()
  const currentYear = new Date().getFullYear()
  const { ts } = useI18n()

  const dateOfBirthConfig = {
    day: {
      type: 'enum',
      token: 'day',
      placeholder: ts('date.day'),
      values: Array.from({ length: 31 }, (_, i) =>
        (i + 1).toString().padStart(2, '0')
      ),
    },
    month: {
      type: 'enum',
      token: 'month',
      placeholder: ts('date.month'),
      values: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, '0')
      ),
    },
    year: {
      type: 'enum',
      token: 'year',
      placeholder: ts('date.year'),
      values: Array.from(
        { length: currentYear - 1899 },
        (_, i) => `${currentYear - i}`
      ),
    },
  }

  const dateOfBirthLabel = computed(() => {
    const dateLabel = ts('date.dateOfBirthLabel')
    const completeDate =
      country === 'us' || country === 'ca'
        ? ts('date.completeDateUS')
        : ts('date.completeDate')
    return `${dateLabel} ${completeDate}`
  })
  return { dateOfBirthConfig, dateOfBirthLabel }
}
