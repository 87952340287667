import { useFormKitContextById } from '@formkit/vue'

export const useFormKitState = (formIds: string[]) => {
  const nodeRefs = formIds.map(id => useFormKitContextById(id))
  const error = ref(false)

  const isLoading = computed(() =>
    nodeRefs.some(nodeRef => nodeRef.value?.state.loading)
  )
  const submitted = computed(() =>
    nodeRefs.some(nodeRef => nodeRef.value?.state.submitted)
  )
  const valid = computed(() =>
    nodeRefs.some(nodeRef => nodeRef.value?.state.valid)
  )
  const errorFormkit = computed(() =>
    nodeRefs.some(nodeRef => nodeRef?.value?.state.errors)
  )
  const success = computed(
    () => valid.value && submitted.value && !isLoading.value && !error.value
  )

  watch(errorFormkit, val => {
    if (val) {
      error.value = val
    }
  })

  watch(isLoading, () => {
    if (!errorFormkit.value) {
      error.value = false
    }
  })
  watch(
    valid,
    valid => {
      if (!valid) {
        error.value = true
      }
    },
    { immediate: true }
  )
  watch(submitted, () => {
    if (!valid.value) {
      error.value = true
    }
  })

  return {
    isLoading,
    submitted,
    valid,
    error,
    success,
  }
}
